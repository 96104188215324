/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export function isNullOrUndefined<T>(obj: null | undefined | T): obj is null | undefined {
  const ref = obj as any;
  return !(ref || ref === 0 || ref === false);
}

export function isNull(obj: any): obj is null {
  return obj === null;
}

export function isUndefined(obj: any): obj is undefined {
  return obj === undefined;
}

export function isNumber(obj: any): obj is number {
  return typeof obj === "number";
}

export function isString(obj: any): obj is string {
  return typeof obj === "string";
}

export function isObject(obj: any): obj is object {
  return typeof obj === "object";
}

export function toDictionary<T>(array: Array<T>, indexKey: keyof T) {
  const normalizedObject: any = {};
  for (let i = 0; i < array.length; i++) {
    const key = array[i][indexKey];
    normalizedObject[key] = array[i];
  }
  return normalizedObject as { [key: string]: T };
}

export const groupArrayByKey = (array: any[], key: string): { string: any[] } => {
  return array.reduce((previousValue, currentValue) => {
    previousValue[currentValue[key]] = previousValue[currentValue[key]] || [];
    previousValue[currentValue[key]].push(currentValue);
    return previousValue;
  }, Object.create(null));
};
