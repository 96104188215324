const key = "sa-chat-widget-visitor-country-code";

export default {
  saveState(state: string | null): boolean {
    try {
      localStorage.setItem(key, state ?? "null");
      return true;
    } catch (error) {
      console.error("Visitor country state cannot be saved", error);
      return false;
    }
  },

  getState(): string | null | undefined {
    try {
      const item = localStorage.getItem(key);

      if (item === "null") {
        return null;
      }

      return item ?? undefined;
    } catch (error) {
      console.error("Visitor country state cannot be retrieved", error);
      return undefined;
    }
  },
};
