import {
  GConnectedServiceChatEntityConversationStartModeItem,
  GConnectedServiceChatEntityOperationModeItem,
  GLocaleItem,
  GNameVariationItem,
} from "@/generated/graphql";
import { ChatListMessage, ChatMessageItem } from "./conversationMessagesPersistence";
import { TenantSettings } from "./models";

export function getDemoMessages(): ChatListMessage[] {
  return [
    {
      id: "1",
      type: ChatMessageItem.Contact,
      content:
        "Hei! Jeg bestilte nettopp et par sko, men ser jeg bestilte feil størrelse. Kan du endre bestillingen til str. 41?",
      isProcessing: false,
      attachments: [],
    },
    {
      id: "2",
      type: ChatMessageItem.Agent,
      content: `Såklart, ingen problem - har oppdatert bestillingen nå &#128522; Den bør bli sendt senest i løpet av morgendagen.`,
      isProcessing: false,
      attachments: [],
    },
    {
      id: "3",
      type: ChatMessageItem.Contact,
      content: "Fantastisk, tusen takk for hjelpen!",
      isProcessing: false,
      attachments: [],
    },
  ];
}

export function getDemoSettings() {
  const settings: TenantSettings = {
    validUntil: Date.now() + 60_000 * 60 * 24, // 24 hours
    behaviour: {
      conversationStartMode: GConnectedServiceChatEntityConversationStartModeItem.StartOnFirstContactMessage,
      operationMode: GConnectedServiceChatEntityOperationModeItem.AgentsAlwaysAvailable,
    },
    conversationSettings: {
      agentNameVariation: GNameVariationItem.FirstNameAndLastNameInitial,
      showAgentAvatar: false,
      showAgentName: true,
    },
    iframeSettings: {
      borderRadius: "8px 8px 0 0",
      bottomOffset: "16px",
      boxShadow: "#0a0c2e1f 0 24px 48px",
      height: "768px",
      rightOffset: "16px",
      width: "420px",
    },
    locale: GLocaleItem.Norwegian,
    text: {
      noAgentsOnlineMessage:
        "Det er dessverre ingen agenter tilgjengelig for øyeblikket. Hvor ønsker du å fortsette samtalen?",
      preChatSurveyHeader: "Heisann 👋",
      preChatSurveySubHeader: "Bare et par spørsmål før vi starter.",
      splashHeaderText: "Ditt firma",
      splashSubHeaderText: "Hvordan kan vi hjelpe?",
      startMessage: "Hei og velkommen til chat'en vår 🙂  Vi svarer deg i våre åpningstider (09-17 mandag til fredag).",
    },
    theme: {
      agentBubbleBackgroundColor: "#e2e8f0",
      agentBubbleTextColor: "#000000",
      appBackgroundColorFrom: "#FFFFFF",
      appBackgroundColorTo: "#FFFFFF",
      botBubbleBackgroundColor: "#e2e8f0",
      botBubbleTextColor: "#000000",
      buttonBackgroundColor: "#f3f4f6",
      buttonBorderColor: "#9ca3af",
      buttonTextColor: "#000000",
      contactBubbleBackgroundColor: "#111827",
      contactBubbleTextColor: "#ffffff",
      iconTextColor: "#000000",
      inputBackgroundColor: "white",
      inputBorderColor: "#888888",
      inputTextColor: "#000000",
      minimizedIconBackgroundColor: "#fed318",
      minimizedIconColor: "#000000",
      primaryHoverColor: "red",
      primaryTextColor: "#000000",
      processingBubbleBackgroundColor: "#fed318",
      processingBubbleTextColor: "#000000",
      topBackgroundColor: "#fed318",
      topTextColor: "#000000",
    },
  };

  return settings;
}
