export enum ChatMessageItem {
  Bot,
  Agent,
  Contact,
  TransferredEvent,
  ChatEndedEvent,
}

export interface ChatListMessageAttachment {
  filename: string;
  size: number;
  previewUrl: string;
  downloadUrl: string;
}

export interface ChatListMessage {
  id: string;
  type: ChatMessageItem | null;
  content: string;
  isProcessing: boolean;
  agentName?: string | null;
  agentInitials?: string | null;
  attachments: ChatListMessageAttachment[];
}

const key = "sa-chat-widget-conversation-messages";

export default {
  saveState(conversation: string, state: ChatListMessage[]): boolean {
    try {
      const lastItems = state.length > 50 ? state.slice(state.length - 50) : state;
      sessionStorage.setItem(`${key}-${conversation}`, JSON.stringify(lastItems));
      return true;
    } catch (error) {
      console.error("State cannot be saved", error);
      return false;
    }
  },

  getState(conversation: string): ChatListMessage[] | null {
    try {
      const item = sessionStorage.getItem(`${key}-${conversation}`);
      return item ? (JSON.parse(item) as ChatListMessage[]) : null;
    } catch (error) {
      console.error("State cannot be retrieved", error);
      return null;
    }
  },
};
