<template>
  <div class="flex w-full space-x-3 max-w-[90%]">
    <div class="chat-bubble-processing flex-shrink-0 h-10 w-10 rounded-full flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1.3em"
        viewBox="0 0 640 512"
      >
        <path
          d="M544 0H96V176H64V160H0v64H64V208H96V416H224v96l144-96H544V208h32v16h64V160H576v16H544V0zM160 96H480V288H160V96zm64 128a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm224-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
          style="fill: var(--icon-text-color);"
        />
      </svg>
    </div>
    <div>
      <div class="chat-bubble-processing p-3 rounded-md text-sm leading-5">
        <div class="dot-elastic ml-4 mr-4"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="sass"></style>
