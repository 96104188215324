import { createApp } from "vue";
import App from "./App.vue";
import { setMinimizedState } from "@/utils/iframe-settings";
import { setCssVar } from "@/utils/set-css-variable";
import { getTenantSettings, getDemoSettings } from "@/utils/get-tenant-settings";
import { installPlugins } from "./plugins";
import { GWidgetConfigurationTheme } from "./generated/graphql";
import { connectedServiceInjectionKey, demoModeInjectionKey, settingsInjectionKey, tenantInjectionKey } from "./keys";
import { useTenantSettingsPersistence } from "./utils/tenantSettingsPersistence";
import emitter from "@/utils/mitt";

const scriptTag = document.currentScript || [].slice.call(document.getElementsByTagName("script")).pop();
const tenantFromTag = scriptTag && scriptTag.getAttribute("tenant");
const connectedServiceFromTag = scriptTag && scriptTag.getAttribute("widget");
const demoFromTag = scriptTag && scriptTag.getAttribute("demo");
const tenantValue = tenantFromTag || process.env.VUE_APP_SA_TENANT;
const connectedServiceValue = connectedServiceFromTag || process.env.VUE_APP_SA_CONNECTED_SERVICE;
const demoMode = demoFromTag || process.env.VUE_APP_DEMO_MODE;

const srcFromTag = scriptTag?.getAttribute("src");

if (!srcFromTag) {
  console.warn("No src in script tag");
}

const tenantSettings = demoMode ? getDemoSettings() : await getTenantSettings(tenantValue, connectedServiceValue);
const { saveState } = useTenantSettingsPersistence(connectedServiceValue ?? "demo");

const div = document.createElement("div");
div.id = "sa-chat-app";
document.body.appendChild(div);

const iframe = document.createElement("iframe");
iframe.id = "sa-chat-widget-iframe";

// Wait for iframe to load to append the div and mount Vue app
iframe.onload = () => {
  const head = iframe.contentWindow?.document.head;
  const link = document.createElement("link");

  const baseUrl = srcFromTag == "/embed.js" ? "" : new URL(srcFromTag ?? "").origin;
  link.href = `${baseUrl}/output.css`;
  link.rel = "stylesheet";
  head?.appendChild(link);

  Object.keys(tenantSettings.value.theme).forEach(key => {
    const varName = key.replace(/([A-Z])/g, "-$1").toLowerCase(); // Convert camelCase to kebab-case
    const themeKey = key as keyof GWidgetConfigurationTheme;
    if (tenantSettings.value.theme[themeKey]) {
      setCssVar(iframe, varName, tenantSettings.value.theme[themeKey] as string);
    }
  });

  setMinimizedState(iframe, tenantSettings.value.iframeSettings);
  iframe.contentWindow?.document.body.appendChild(div);

  // Mount Vue app to div inside iframe
  const app = createApp(App);
  installPlugins(app, tenantSettings.value.locale);
  app.config.globalProperties.$tenant = tenantValue;
  app.config.globalProperties.$connectedservice = connectedServiceValue;
  app.config.globalProperties.$settings = tenantSettings;

  app.provide(settingsInjectionKey, tenantSettings);
  app.provide(tenantInjectionKey, tenantValue);
  app.provide(connectedServiceInjectionKey, connectedServiceValue);
  app.provide(demoModeInjectionKey, demoMode);
  // app.config.globalProperties.$askForName = askForNameValue;
  // app.config.globalProperties.$askForEmail = askForEmailValue;
  app.mount(div);
};

document.body.appendChild(iframe);

export function setTheme(key: string, color: string) {
  console.log("setting theme", key, color);

  if (Object.keys(tenantSettings.value.theme).find(i => i === key)) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    //(tenantSettings.value.theme[k] as any) = color;
    const varName = key.replace(/([A-Z])/g, "-$1").toLowerCase(); // Convert camelCase to kebab-case
    const themeKey = key as keyof GWidgetConfigurationTheme;
    saveState({
      ...tenantSettings.value,
      theme: {
        ...tenantSettings.value.theme,
        [themeKey]: color,
      },
    });
    setCssVar(iframe, varName, color);
  } else {
    console.warn("Unknown theme key", key);
  }
}

export function open() {
  emitter.emit("open-requested");
}

export function close() {
  emitter.emit("close-requested");
}
