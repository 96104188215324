import { GWidgetConfigurationIframe } from "@/generated/graphql";

export function setMinimizedState(iframe: HTMLIFrameElement, iframeSettings: GWidgetConfigurationIframe) {
  console.debug("Setting minimized state");

  iframe.contentDocument?.body.classList.remove("bg-app");
  iframe.style.border = "none";
  iframe.width = "56px";
  iframe.height = "56px";
  iframe.style.position = "fixed";
  iframe.style.zIndex = "2147483647";
  iframe.style.right = iframeSettings.rightOffset;
  iframe.style.bottom = iframeSettings.bottomOffset;
  iframe.style.height = "";
  // iframe.style.maxHeight = "";
  iframe.style.boxShadow = "";
  iframe.style.borderRadius = "";
}

export function setExpandedState(iframe: HTMLIFrameElement, iframeSettings: GWidgetConfigurationIframe) {
  console.debug("Setting expanded state");

  iframe.contentDocument?.body.classList.add("bg-app");
  iframe.style.border = "none";
  iframe.style.position = "fixed";
  iframe.style.zIndex = "2147483647";

  const screenWidth: number = document.documentElement.clientWidth;
  const screenHeight: number = window.innerHeight;

  // Screen is smaller than the default width/height. Shrink to fit screen.
  if (parseInt(iframeSettings.width.replace("px", ""), 10) > screenWidth) {
    iframe.width = `${screenWidth}px`;
    iframe.height = `${screenHeight}px`;
    iframe.style.right = "0px";
    iframe.style.bottom = "0px";

    iframe.style.height = `${screenHeight}px`;
    iframe.style.maxHeight = `${screenHeight}px`;
    iframe.style.boxShadow = "";
    iframe.style.borderRadius = "";
  }

  // Use default dimension
  else {
    iframe.width = iframeSettings.width;
    iframe.height = iframeSettings.height;
    iframe.style.right = iframeSettings.rightOffset;
    iframe.style.bottom = iframeSettings.bottomOffset;

    const bottomOffset = parseInt(iframeSettings.bottomOffset.replace("px", ""), 10);
    iframe.style.height = `calc(100vh - ${bottomOffset * 2}px)`;
    iframe.style.maxHeight = iframeSettings.height;
    iframe.style.boxShadow = iframeSettings.boxShadow;
    iframe.style.borderRadius = iframeSettings.borderRadius;
  }
}
