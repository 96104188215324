import { onBeforeUnmount } from "vue";
import { MittEvents } from "@/utils/mitt-events";
import emitter from "@/utils/mitt";
import { isNullOrUndefined } from "@/utils/object-utils";

type EventType = keyof MittEvents;

interface RegisteredMittEvent<T extends EventType> {
  type: T;
  callback: (data: MittEvents[T]) => void;
}

export function useEmitterOn<T extends keyof MittEvents>(type: T, callback: (data: MittEvents[T]) => void) {
  emitter.on(type, callback);

  const registeredMittEvent: RegisteredMittEvent<T> = {
    type: type,
    callback: callback,
  };

  onBeforeUnmount(() => {
    if (!isNullOrUndefined(registeredMittEvent)) {
      emitter.off(registeredMittEvent.type, registeredMittEvent.callback);
    }
  });
}
