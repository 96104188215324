import { GConversationTimelineDataFragment } from "@/generated/graphql";

export function getConversationTimelineContent (data: GConversationTimelineDataFragment): string {
  switch (data.__typename) {
    case "ConversationAgentMessage":
      return data.content;

    case "ConversationBotMessage":
      return data.content;

    case "ConversationContactMessage":
      return data.content;

    case "ConversationEvent":
      if (data.metadata?.__typename === "ConversationTransferredMetaData") {
        return data.metadata.toContactAddress?.address ?? "";
      } else {
        return "";
      }
  }
}

