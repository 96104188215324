export function useActiveConversationPersistence(connectedServiceId: string) {
  const key = `sa-chat-widget-active-conversation-${connectedServiceId}`;

  return {
    saveState(activeConversation: string): boolean {
      try {
        sessionStorage.setItem(key, activeConversation);
        return true;
      } catch (error) {
        console.error("State cannot be saved", error);
        return false;
      }
    },

    getState(): string | null {
      try {
        const item = sessionStorage.getItem(key);
        return item ? item : null;
      } catch (error) {
        console.error("State cannot be retrieved", error);
        return null;
      }
    },
  };
}
