import { diffChars } from "diff";
import { onBeforeUnmount, Ref } from "vue";

export interface TextPatch {
  start: number;
  del: number;
  insert: string;
}

export function useChangeWatcher(
  editor: Ref<HTMLTextAreaElement | undefined>,
  diffCallback: (formattedDiffs: TextPatch[], sequence: number) => void,
  snapshotCallback: (text: string, sequence: number) => void
) {
  let previousText = "";
  let sequence = 0;
  let previousSnapshot = new Date().getTime();

  const refreshTimerId = setInterval(() => {
    const currentText = editor.value?.value ?? "";
    const differences = formatDifferences(previousText, currentText);

    if (differences.length) {
      sequence++;
      previousText = currentText;

      if (new Date().getTime() - previousSnapshot >= 10 * 1000) {
        console.debug("Full snapshot, sequence", sequence);
        previousSnapshot = new Date().getTime();
        snapshotCallback(currentText, sequence);
      } else {
        console.debug("Sending", differences.length, "differences, sequence", sequence)
        diffCallback(differences, sequence);
      }
    }
  }, 2000);

  function formatDifferences(previousText: string, currentText: string) {
    const differences = diffChars(previousText, currentText);

    let offset = 0;
    const formattedDiffs: TextPatch[] = [];

    for (const part of differences) {
      if (part.added) {
        formattedDiffs.push({ start: offset, del: 0, insert: part.value });
        offset += part.value.length;
      } else if (part.removed) {
        formattedDiffs.push({ start: offset, del: part.value.length, insert: "" });
      } else {
        offset += part.value.length;
      }
    }

    return formattedDiffs;
  }

  onBeforeUnmount(() => {
    console.debug("Cleared interval", refreshTimerId);
    clearInterval(refreshTimerId);
  });

  function reset () {
    previousText = "";
    sequence = 0;
    previousSnapshot = new Date().getTime();
  }

  return { reset };
}
