import { GWidgetConfigurationPreChatSurveyField } from "@/generated/graphql";

export interface PreChatSurveyData
  extends Omit<GWidgetConfigurationPreChatSurveyField, "hint" | "placeholder" | "required"> {
  value: unknown;
}

const key = "sa-chat-widget-pre-chat-survey";

export default {
  saveState(state: PreChatSurveyData[]): boolean {
    try {
      sessionStorage.setItem(key, JSON.stringify(state));
      return true;
    } catch (error) {
      console.error("State cannot be saved", error);
      return false;
    }
  },

  getState(): PreChatSurveyData[] | null {
    try {
      const item = sessionStorage.getItem(key);
      return item ? (JSON.parse(item) as PreChatSurveyData[]) : null;
    } catch (error) {
      console.error("State cannot be retrieved", error);
      return null;
    }
  },
};
