import { createClient } from "graphql-sse";
import { DocumentNode } from "graphql/language";
import { print } from "graphql";

const API_ENDPOINT = process.env.VUE_API;

if (!API_ENDPOINT) {
  throw new Error("Missing API in env");
}

const subscriptionClient = createClient({
  // singleConnection: true, preferred for HTTP/1 enabled servers and subscription heavy apps
  url: API_ENDPOINT,
  retryAttempts: 1000,
  //onNonLazyError: console.warn,
  retry: async () => {
    await new Promise(resolve => {
      console.debug("Attempting subscription reconnect");
      setTimeout(resolve, 1000 + Math.random() * 3000);
    });
  },
  onMessage: console.debug,
});

export function subscribe<T, TVar extends Record<string, unknown> | undefined>(
  query: DocumentNode,
  variables: TVar,
  callback: (data: T) => void,
  onReconnect: () => void
) {
  return subscriptionClient.subscribe<T>(
    {
      query: print(query),
      variables: variables,
    },
    {
      next: data => {
        if (data.data !== undefined && data.data !== null) {
          const result = data.data as T;
          callback(result);
        }
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
        // console.log("completed");
      },
    },
    {
      connected(reconnected) {
        if (reconnected) {
          onReconnect();
        }
      },
    }
  );
}
