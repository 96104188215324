import { isNullOrUndefined } from "@/utils/object-utils";
import { GNameVariationItem } from "../generated/graphql";

export const resolveDisplayName = (
  firstName: string | undefined | null,
  lastName: string | undefined | null,
  variation: GNameVariationItem = GNameVariationItem.FirstNameAndLastNameInitial
) => {
  const firstInitial = firstName ? firstName[0] : "";
  const lastInitial = lastName ? lastName[0] : "";

  switch (variation) {
    case GNameVariationItem.FullName:
      return (firstName || "") + (lastName ? ` ${lastName}` : "");

    case GNameVariationItem.FirstNameOnly:
      return firstName || "";

    case GNameVariationItem.LastNameOnly:
      return lastName || "";

    case GNameVariationItem.InitialsOnly:
      return `${firstInitial}${lastInitial ? ` ${lastInitial}` : ""}`;

    case GNameVariationItem.FirstNameAndLastNameInitial:
      return firstName ? `${firstName}${lastInitial ? ` ${lastInitial}.` : ""}` : "";

    default:
      return "";
  }
};

export const resolveNameInitials = (firstName: string | undefined | null, lastName: string | undefined | null) => {
  if (!isNullOrUndefined(firstName) && !isNullOrUndefined(lastName)) {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }

  if (!isNullOrUndefined(firstName)) {
    return `${firstName.charAt(0).toUpperCase()}`;
  }

  if (!isNullOrUndefined(lastName)) {
    return `${lastName.charAt(0).toUpperCase()}`;
  }

  return "?";
};
