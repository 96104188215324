import visitorCountryPersistence from "./visitorCountryPersistence";
import { CountryCode, getCountryCallingCode, isSupportedCountry } from "libphonenumber-js";

const lookupVisitorCountryCode = async () => {
  try {
    const res = await fetch("https://d1m3kphglvh8g8.cloudfront.net/");
    const country = res.headers.get("Viewer-Country");

    if (country && isSupportedCountry(country)) {
      return getCountryCallingCode(country as CountryCode);
    }
  } catch (err) {
    console.warn("error getting visitor country");
  }
};

export async function checkAndPersistVisitorCountryCode() {
  const state = visitorCountryPersistence.getState();

  if (state || state === null) {
    return;
  }

  const visitorCountry = await lookupVisitorCountryCode();

  if (visitorCountry) {
    visitorCountryPersistence.saveState(visitorCountry);
  } else {
    visitorCountryPersistence.saveState(null);
  }
}
