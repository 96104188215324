export interface ConversationHistory {
  conversation: string;
  content: string;
  date: number;
  ended: boolean;
}

const key = "sa-chat-widget-conversations";

export default {
  addConversation(conversation: ConversationHistory) {
    const conversations = this.getState();

    if (conversations) {
      let lastItems = conversations.length > 30 ? conversations.slice(conversations.length - 30) : conversations;
      lastItems.push(conversation);
      lastItems = lastItems.sort((a, b) => b.date - a.date);
      this.saveState(lastItems);
    } else {
      this.saveState([conversation]);
    }
  },

  saveState(state: ConversationHistory[]): boolean {
    try {
      const sorted = state.sort((a, b) => b.date - a.date);
      sessionStorage.setItem(key, JSON.stringify(sorted));
      return true;
    } catch (error) {
      console.error("State cannot be saved", error);
      return false;
    }
  },

  getState(): ConversationHistory[] | null {
    try {
      const item = sessionStorage.getItem(key);
      return item ? (JSON.parse(item) as ConversationHistory[]) : null;
    } catch (error) {
      console.error("State cannot be retrieved", error);
      return null;
    }
  },
};
