<template>
  <div class="rounded-lg cursor-pointer">
    <div class="px-2 py-2 flex flex-row items-center space-x-3">
      <div class="flex-shrink-0">
        <div class="rounded-full flex justify-center items-center w-9 h-9 conversation-history-bubble">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path
              d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z"
              style="fill: var(--agent-bubble-text-color)"
            />
          </svg>
        </div>
      </div>

      <div class="text-md w-full min-w-0">
        <div class="text-primary text-sm truncate line-clamp-2">
          <slot name="content" />
        </div>

        <div class="text-primary-faded text-xs">
          <slot name="date" />
        </div>
      </div>

      <div>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
          <path
            d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
            style="fill: var(--icon-text-color)"
          />
        </svg>
      </div>
    </div>

    <div class="chat-message-divider my-1"></div>
  </div>
</template>

<script lang="ts" setup></script>
