import { GraphQLClient } from "graphql-request";
import { getSdk } from "@/generated/graphql";

export function useGraphqlSdk() {
  if (!process.env.VUE_API) {
    throw new Error("API is not defined in config");
  }

  const client = new GraphQLClient(process.env.VUE_API);

  // const jwt = await getJwt();
  //
  // if (jwt !== null && jwt !== "") {
  //   client.setHeader("Authorization", `Bearer ${jwt}`);
  // }

  return getSdk(client);
}
