import { TenantSettings } from "@/utils/models";
import { ComputedRef, computed, ref } from "vue";

const settings = ref<TenantSettings>();

export const useTenantSettingsPersistence = (connectedServiceId: string) => {
  const key = `sa-chat-widget-settings-2-${connectedServiceId}`;

  return {
    saveState(state: TenantSettings): boolean {
      settings.value = state;

      try {
        // TODO debounce
        localStorage.setItem(key, JSON.stringify(state));
        return true;
      } catch (error) {
        console.error("State cannot be saved", error);
        return false;
      }
    },

    getState(): ComputedRef<TenantSettings> | null {
      if (settings.value) {
        return computed(() => settings.value!);
      }

      try {
        const item = localStorage.getItem(key);

        if (item) {
          settings.value = JSON.parse(item) as TenantSettings;
          return computed(() => settings.value!);
        }

        return null;
      } catch (error) {
        console.error("State cannot be retrieved", error);
        return null;
      }
    },
  };
};
