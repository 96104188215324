import { App } from "vue";
import { createProPlugin, inputs } from "@formkit/pro";
import { plugin, defaultConfig } from "@formkit/vue";
import { arrowDown, arrowUp, circle, check, close, down, fileDoc, spinner, star, trash } from "@formkit/icons";
import { nb } from "@formkit/i18n";
import { createAutoAnimatePlugin } from "@formkit/addons";
import { isValidPhoneNumber as externalIsValidPhoneNumber } from "libphonenumber-js";
import { FormKitNode } from "@formkit/core";
import { generateClasses } from "@formkit/themes";
import theme from "./formkit-theme";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { GLocaleItem } from "./generated/graphql";

const isValidPhoneNumber = (node: FormKitNode) => {
  if (!node.value) {
    return false;
  }

  // await node.input(new AsYouType().input(node.value as string));

  try {
    return externalIsValidPhoneNumber(node.value as string);
  } catch (err) {
    console.warn("Could not parse", err);
    return false;
  }
};

export function installPlugins(app: App, language: GLocaleItem) {
  app.use(autoAnimatePlugin);

  const formkitLocaleStr = language === GLocaleItem.Norwegian ? "nb" : "en";

  const pro = createProPlugin("fk-5bd1f671f", inputs);

  app.use(
    plugin,
    defaultConfig({
      config: {
        classes: generateClasses(theme),
      },
      rules: {
        phone: isValidPhoneNumber,
      },
      locales: { nb }, // en is added by default
      locale: formkitLocaleStr,
      icons: {
        checkboxDecorator: check,
        arrowDown,
        arrowUp,
        close,
        fileItem: fileDoc,
        fileRemove: close,
        noFiles: fileDoc,
        radioDecorator: circle,
        select: down,
        spinner,
        star,
        trash,
      },
      plugins: [pro, createAutoAnimatePlugin()],
    })
  );
}
