export const useContactPersistence = (tenantId?: string) => {
  if (!tenantId) {
    return {
      saveState: () => true,
      getState: (): string | null => "demo",
    };
  }

  const key = `sa-chat-widget-contact-${tenantId}`;

  return {
    saveState(contact: string): boolean {
      try {
        localStorage.setItem(key, contact);
        return true;
      } catch (error) {
        try {
          document.cookie = `${key}=${JSON.stringify(contact)}`;
          return true;
        } catch (error) {
          console.error("State cannot be saved", error);
          return false;
        }
      }
    },

    getState(): string | null {
      try {
        const item = localStorage.getItem(key);
        return item ? item : null;
      } catch (error) {
        try {
          const cookie = document.cookie.split("; ").find(row => row.startsWith(`${key}=`));

          if (cookie) {
            const [, value] = cookie.split("=");
            return value;
          }

          return null;
        } catch (error) {
          console.error("State cannot be retrieved", error);
          return null;
        }
      }
    },
  };
};
