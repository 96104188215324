<template>
  <div class="chat-bubble flex w-full space-x-3 ml-auto justify-end max-w-[90%]">
    <div class="chat-bubble-contact py-2 px-3 rounded-md leading-5 text-md">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="sass"></style>
