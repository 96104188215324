export function isScrolledNearBottom (div: HTMLDivElement | undefined, delta = 200): boolean {
  if (!div) {
    return false;
  }

  const scrollPosition = div.scrollTop + div.clientHeight;
  const divScrollHeight = div.scrollHeight;
  return divScrollHeight - scrollPosition <= delta;
}

export function scrollToBottomInstant(div: HTMLDivElement | undefined): void {
  if (!div) {
    return;
  }

  div.scrollTop = div.scrollHeight;
}

export function scrollToBottomSmooth(div: HTMLDivElement | undefined): void {
  if (!div) {
    return;
  }

  div.scrollTo({
    top: div.scrollHeight,
    behavior: 'smooth'
  });
}

export function containsHtmlTags(str: string): boolean {
  const regex = /<\/?[a-z][\s\S]*>/i;
  return regex.test(str);
}