import { useTenantSettingsPersistence } from "@/utils/tenantSettingsPersistence";
import { isNullOrUndefined } from "@/utils/object-utils";
import { TenantSettings } from "@/utils/models";
import { useGraphqlSdk } from "@/graphql/graphql-client";
import { ComputedRef } from "vue";
import { getDemoSettings as demoSettings } from "./demo";

export async function getTenantSettings(
  tenantId: string,
  connectedServiceId: string
): Promise<ComputedRef<TenantSettings>> {
  const tenantSettingsPersistence = useTenantSettingsPersistence(connectedServiceId);

  let tenantSettings = tenantSettingsPersistence.getState();

  if (
    isNullOrUndefined(tenantSettings) ||
    !tenantSettings.value.validUntil ||
    tenantSettings.value.validUntil < Date.now()
  ) {
    const sdk = useGraphqlSdk();

    if (!tenantId) {
      throw new Error("TenantId is missing");
    }

    console.debug("Fetching config from api");

    const queryRes = await sdk.GetWidgetConfiguration({
      tenant: tenantId,
      widgetId: connectedServiceId,
    });

    if (queryRes.widgetConfiguration.error) {
      throw new Error(`Error getting widget configuration: ${queryRes.widgetConfiguration.error}`);
    }

    if (!queryRes.widgetConfiguration.configuration) {
      throw new Error(`Error getting widget configuration: empty result`);
    }

    const settings = {
      ...queryRes.widgetConfiguration.configuration,
      validUntil: Date.now() + 60_000, // 1 minute
    };

    tenantSettingsPersistence.saveState(settings);
    tenantSettings = tenantSettingsPersistence.getState();

    if (!tenantSettings) {
      throw new Error("Failed to get settings ref after saving");
    }
  }

  return tenantSettings;
}

export function getDemoSettings(): ComputedRef<TenantSettings> {
  const tenantSettingsPersistence = useTenantSettingsPersistence("demo");

  let tenantSettings = tenantSettingsPersistence.getState();

  if (
    isNullOrUndefined(tenantSettings) ||
    !tenantSettings.value.validUntil ||
    tenantSettings.value.validUntil < Date.now()
  ) {
    const settings = demoSettings();

    tenantSettingsPersistence.saveState(settings);
    tenantSettings = tenantSettingsPersistence.getState();

    if (!tenantSettings) {
      throw new Error("Failed to get settings ref after saving");
    }
  }

  return tenantSettings;
}
