import { useEmitterOn } from "@/composables/useEmitterOn";
import conversationMessagesPersistence, {
  ChatListMessageAttachment,
  ChatMessageItem,
} from "@/utils/conversationMessagesPersistence";
import conversationHistoryPersistence from "@/utils/conversationHistoryPersistence";
import emitter from "@/utils/mitt";
import { nextTick } from "vue";

export function useConversationEventListener() {
  useEmitterOn("contact-message-sent", async msg => {
    addToConversationMessages(
      msg.id,
      msg.conversation,
      msg.content,
      ChatMessageItem.Contact,
      false,
      null,
      null,
      msg.attachments
    );
    updateConversationList(msg.conversation, msg.content);
    await nextTick();
    emitter.emit("messages-updated", msg.conversation);
  });

  useEmitterOn("agent-message-received", async msg => {
    addToConversationMessages(
      msg.id,
      msg.conversation,
      msg.content,
      ChatMessageItem.Agent,
      false,
      msg.agentName,
      msg.agentInitials,
      msg.attachments
    );
    updateConversationList(msg.conversation, msg.content);
    await nextTick();
    emitter.emit("messages-updated", msg.conversation);
  });

  useEmitterOn("bot-message-received", async msg => {
    addToConversationMessages(
      msg.id,
      msg.conversation,
      msg.content,
      ChatMessageItem.Bot,
      msg.processing,
      null,
      null,
      []
    );
    updateConversationList(msg.conversation, msg.content);
    await nextTick();
    emitter.emit("messages-updated", msg.conversation);
  });

  useEmitterOn("event-transferred-received", async msg => {
    addToConversationMessages(
      msg.id,
      msg.conversation,
      msg.content,
      ChatMessageItem.TransferredEvent,
      false,
      null,
      null,
      []
    );
    updateConversationList(msg.conversation, msg.content);
    await nextTick();
    emitter.emit("messages-updated", msg.conversation);
  });

  function addToConversationMessages(
    id: string,
    conversation: string,
    content: string,
    type: ChatMessageItem,
    processing: boolean,
    agentName: string | null | undefined,
    agentInitials: string | null | undefined,
    attachments: ChatListMessageAttachment[]
  ) {
    const storedConversationMessages = conversationMessagesPersistence.getState(conversation) ?? [];

    storedConversationMessages.push({
      id: id,
      content: content,
      type: type,
      isProcessing: processing,
      agentName: agentName,
      agentInitials: agentInitials,
      attachments: attachments,
    });

    conversationMessagesPersistence.saveState(conversation, storedConversationMessages);
  }

  function updateConversationList(conversation: string, content: string) {
    const storedConversations = conversationHistoryPersistence.getState() ?? [];

    const index = storedConversations.findIndex(i => i.conversation === conversation);

    if (index === -1) {
      storedConversations.push({
        date: new Date().getTime(),
        content: content,
        conversation: conversation,
        ended: false,
      });
    } else {
      storedConversations[index].date = new Date().getTime();
      storedConversations[index].content = content;
    }

    conversationHistoryPersistence.saveState(storedConversations);
  }

  return {};
}
