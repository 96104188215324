<template>
  <div class="chat-bubble w-full space-y-2">
    <div class="chat-bubble-event py-2 px-3 rounded-md leading-5 text-sm text-center">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="sass"></style>
