import {
  GChatPreChatSurveyFieldTargetEntityType,
  GCreateConversationContactInput,
  GPreChatSurveyDataInput,
} from "@/generated/graphql";
import { useGraphqlSdk } from "@/graphql/graphql-client";
import mitt from "@/utils/mitt";
import { useActiveConversationPersistence } from "./activeConversationPersistence";
import { useContactPersistence } from "./contactPersistence";
import { isNullOrUndefined } from "./object-utils";
import preChatSurveyPersistence from "./preChatSurveyPersistence";
import emitter from "@/utils/mitt";

export async function createConversation(
  content: string,
  token: string | undefined | null,
  tenant: string,
  connectedService: string
): Promise<{ error?: string; botIsLoading: boolean }> {
  const contactPersistence = useContactPersistence(tenant);
  const activeConversationPersistence = useActiveConversationPersistence(connectedService);

  const contactByProperties: GCreateConversationContactInput = {};
  const surveyInformation = preChatSurveyPersistence.getState();

  if (token) {
    contactByProperties.byToken = token;
  } else {
    contactByProperties.byProperties =
      surveyInformation
        ?.filter(i => i.targetEntity === GChatPreChatSurveyFieldTargetEntityType.Contact && i.value)
        .map<GPreChatSurveyDataInput>(f => {
          const temp: GPreChatSurveyDataInput = {
            fieldId: f.id,
            label: f.label,
            type: f.type,
            value: f.value,
          };
          return temp;
        }) ?? [];
  }

  const conversationSurveyData = surveyInformation
    ?.filter(i => i.value)
    .map<GPreChatSurveyDataInput>(f => {
      const temp: GPreChatSurveyDataInput = {
        fieldId: f.id,
        label: f.label,
        type: f.type,
        value: f.value,
      };
      return temp;
    });

  const sdk = useGraphqlSdk();

  try {
    const res = await sdk.CreateConversation({
      content: content,
      tenant: tenant,
      connectedService: connectedService,
      contact: contactByProperties,
      conversationSurveyData: conversationSurveyData,
    });

    if (res.createConversation.errors?.length) {
      console.warn(res.createConversation.errors);
      throw new Error("Error creating conversation");
    }

    if (!isNullOrUndefined(res.createConversation.conversation) && !isNullOrUndefined(res.createConversation.contact)) {
      const contact = res.createConversation.contact;
      contactPersistence.saveState(contact);

      activeConversationPersistence.saveState(res.createConversation.conversation);

      mitt.emit("contact-message-sent", {
        id: "1",
        content: content,
        conversation: res.createConversation.conversation,
        noAgentAvailable: res.createConversation.noAgentAvailable ?? false,
        botAssistant: res.createConversation.botAssistant ?? false,
        attachments: [],
      });

      mitt.emit("contact-created", res.createConversation.contact);

      if (res.createConversation.noAgentAvailable === true) {
        setTimeout(() => {
          // Delay in case conversation is created from App.vue. Need to give chat-list time to mount before emitting event
          emitter.emit("no-agents-available");
        }, 1000);
      }

      return {
        error: undefined,
        botIsLoading: !!res.createConversation.botAssistant,
      };
    }

    throw new Error("Conversation or contact was null");
  } catch (err) {
    console.warn(err);
    return {
      error: "Could not create conversation",
      botIsLoading: false,
    };
  }
}
