import { GConnectedServiceChatEntity } from "@/generated/graphql";

export const enum ActiveWidget {
  Minimized = 1,
  Splash = 2,
  Conversation = 3,
  PreChatSurvey = 4,
}

export interface TenantSettings extends GConnectedServiceChatEntity {
  validUntil: number;
}
