import { ActiveWidget } from "@/utils/models";

const key = "sa-chat-widget-active-component";

export default {
  saveState (widget: ActiveWidget): boolean {
    try {
      sessionStorage.setItem(key, widget.toString());
      return true;
    } catch (error) {
      console.error("State cannot be saved", error);
      return false;
    }
  },

  getState (): number | null {
    try {
      const item = sessionStorage.getItem(key);
      return item ? parseInt(item, 10) : null;
    } catch (error) {
      console.error("State cannot be retrieved", error);
      return null;
    }
  }
};
